<template>
  <div id="account">
    <noOpen v-if="status == 2" :list="list"></noOpen>
    <div v-else class="main">
      <!-- 短信验证保护 -->
      <div class="title">
        短信验证保护
        <el-switch
          style="margin-left:20px"
          active-value="1"
          inactive-value="2"
          v-model="post.is_msg_safe"
        ></el-switch>
      </div>
      <div class="divider"></div>

      <div class="model" v-if="post.is_msg_safe == 1">
        <div class="flex">
          <div class="left" style="margin-right:60px">
            24小时内不同IP登录次数超过
          </div>
          <div class="rigth flex-center">
            <el-input
              style="width:163px"
              type="text"
              placeholder="请输入数字"
              :value="post.msg_repeat_num"
              @input="post.msg_repeat_num = toNumber(arguments[0], true)"
            ></el-input>
            <div class="right_font2">
              个时，会自动发送短信验证账号，可输入0~500，0个表示不限制
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="left">
            24小时内异地登录次数超过
          </div>
          <div class="rigth flex-center">
            <el-input
              style="width:163px"
              type="text"
              placeholder="请输入数字"
              :value="post.msg_offsite_num"
              @input="post.msg_offsite_num = toNumber(arguments[0], true)"
            ></el-input>
            <div class="right_font2">
              个时，会自动发送短信验证账号，可输入0~500，0个表示不限制
            </div>
          </div>
        </div>
      </div>

      <!-- 冻结账号保护 -->
      <div class="title2">
        冻结(拉黑)账号保护
        <el-switch
          style="margin-left:20px"
          active-value="1"
          inactive-value="2"
          v-model="post.is_freeze_safe"
        ></el-switch>
      </div>
      <div class="divider"></div>

      <div class="model" v-if="post.is_freeze_safe == 1">
        <div class="flex">
          <div class="left" style="margin-right:60px">
            24小时内不同IP登录次数超过
          </div>
          <div class="rigth flex-center">
            <el-input
              style="width:163px"
              type="text"
              placeholder="请输入数字"
              :value="post.freeze_repeat_num"
              @input="post.freeze_repeat_num = toNumber(arguments[0], true)"
            ></el-input>
            <div class="right_font2">
              个时，会自动冻结账号(拉入黑名单)，无法访问网校，可输入0~500，0个表示不限制
            </div>
          </div>
        </div>

        <div class="flex">
          <div class="left">
            24小时内异地登录次数超过
          </div>
          <div class="rigth flex-center">
            <el-input
              style="width:163px"
              type="text"
              placeholder="请输入数字"
              :value="post.freeze_offsite_num"
              @input="post.freeze_offsite_num = toNumber(arguments[0], true)"
            ></el-input>
            <div class="right_font2">
              个时，会自动冻结账号(拉入黑名单)，无法访问网校，可输入0~500，0个表示不限制
            </div>
          </div>
        </div>
      </div>

      <el-button
        style="width:130px;margin-top:40px"
        type="primary"
        @click="submit"
      >
        保存
      </el-button>
    </div>

    <copyrightprotect></copyrightprotect>
  </div>
</template>

<script>
import noOpen from '@/components/copyrightprotect/noOpen' //功能介绍
import copyrightprotect from '@/components/copyrightprotect/index' //轮播图
export default {
  name: 'account',
  data() {
    return {
      status: 1,
      dateList: [],
      list: {
        status: 1,
        src: require('@/assets/img/1.3.8/icon_bqbhtb.png'),
        title: '数字版权保护系统',
        describe:
          '三项专利技术加持，集合视频保护、品牌保护、账号保护等3项技术…%追踪翻录视频的账号',
        button_name: '申请试用',
        money: 1999,
        strike: 3999,
        function_tu: require('@/assets/img/1.3.8/img_bqjs.jpg'),
        tip: '/年',
      },
      post: {
        config_id: '',
        is_msg_safe: '2',
        msg_repeat_num: '',
        msg_offsite_num: '',
        is_freeze_safe: '2',
        freeze_repeat_num: '',
        freeze_offsite_num: '',
        is_bind_pc: '2',
      },
    }
  },
  // watch: {
  //   post: {
  //     handler() {
  //       this.$nextTick(() => {
  //         if (this.status == 1) {
  //           const option = _.assign({}, this.post)
  //           this.submit(option)
  //         }
  //       })
  //     },
  //     deep: true,
  //     immediate: true
  //   }
  // },

  created() {
    this.status = this.$store.state.userInfo.jg_is_drm
    if (this.status == 1) {
      this.getDate()
    }
  },
  methods: {
    getDate() {
      this.$http({
        url: `/AccountSafeConfig/editAccountSafe?`,
        callback: ({ data, code }) => {
          if (code == 200) {
            this.dateList = data
            this.post = {
              config_id: data.config_id,
              is_msg_safe: data.is_msg_safe,
              msg_repeat_num: data.msg_repeat_num,
              msg_offsite_num: data.msg_offsite_num,
              is_freeze_safe: data.is_freeze_safe,
              freeze_repeat_num: data.freeze_repeat_num,
              freeze_offsite_num: data.freeze_offsite_num,
              is_bind_pc: data.is_bind_pc,
            }
          }
        },
        error: error => {
          if (error.code == 204) {
            this.dateList = []
          }
        },
      })
    },
    submit() {
      if (
        (this.post.msg_repeat_num > 500 && this.post.is_msg_safe == 1) ||
        (this.post.msg_offsite_num > 500 && this.post.is_msg_safe == 1) ||
        (this.post.freeze_repeat_num > 500 && this.post.is_freeze_safe == 1) ||
        (this.post.freeze_offsite_num > 500 && this.post.is_freeze_safe == 1)
      ) {
        this.$root.prompt({
          msg: '数字只可输入0~500',
        })
        return
      }
      this.$http({
        url: '/AccountSafeConfig/editAccountSafe',
        data: this.post,
        callback: ({ code }) => {
          if (code == 200) {
            this.$root.prompt({
              msg: '保存成功',
              type: 'success',
            })
            // this.getDate()
          }
        },
        // error: error => {
        //   if (error.code == 204) {
        //   }
        // }
      })
    },
  },
  components: {
    copyrightprotect,
    noOpen,
  },
}
</script>

<style lang="scss" scoped>
#account {
  .main {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;

    .title,
    .title2 {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
    }

    .title2 {
      margin-top: 40px;
    }
    .divider {
      width: 100%;
      height: 1px;
      background: #e8e8e8;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .computer {
      font-size: 14px;
      color: #666666;
      line-height: 14px;
    }

    ::v-deep .el-textarea__inner {
      background-color: #f5f5f5;
    }

    .horse {
      display: flex;
      .horse_left {
        width: 540px;
      }
      .horse_right {
        padding-right: 20px;
        width: calc(100% - 540px);
        .right_font1 {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          line-height: 18px;
          margin-bottom: 21px;
        }
        .rignt_tu {
          width: 380px;
          height: 214px;
          position: relative;
          background-color: #000;
        }
      }
    }
    .model {
      font-size: 14px;
      color: #333333;
      line-height: 19px;
      margin-left: 20px;
      .flex {
        margin-top: 20px;
        display: flex;
        align-items: center;
        .left {
          margin-right: 74px;
        }
        .left1 {
          margin-right: 42px;
        }
        .right_font2 {
          margin-left: 10px;
          font-size: 12px;
          color: #999999;
          line-height: 12px;
        }
        .right_tu2 {
          height: 50px;
          width: 50px;
          cursor: pointer;
          background-color: grey;
        }
        .check2 {
          ::v-deep .checkbox-group {
            width: 200px;
          }
        }
      }
      .flex1 {
        margin-top: 20px;
        display: flex;
        .left {
          margin-right: 74px;
        }
        .left1 {
          margin-right: 42px;
        }
      }
    }
  }
}
</style>
